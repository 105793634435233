import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashboardJSON from './AppSettingManagement.json'
import { appSettingActions } from '../../_actions';
import CreateAppSettingModal from "./components/CreateAppSettingModal/CreateAppSettingModal";
import UpdateAppSettingModal from "./components/UpdateAppSettingModal/UpdateAppSettingModal";
import ViewAppSettingModal from "./components/ViewAppSettingModal/ViewAppSettingModal";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ReactPaginate from 'react-paginate';
import { isMobile } from "react-device-detect";
import Loader from '../../components/Loader/Loader';
import { FaRegSave } from "react-icons/fa";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// import Table from '../../components/Atoms/Table/Table';


const AppSettingManagement = () => {
  const dispatch = useDispatch();
  let [selectedFile, setSelectedFile] = useState(null);
  let [rowData, setRowData] = useState(null);
  let [updateModal, setUpdateModal] = useState(false);
  let [createModal, setCreateModal] = useState(false);
  let [viewModal, setViewModal] = useState(false);
  const [fieldsUpdate, setFieldsUpdate] = useState({});
  const [errorsUpdate, setErrorsUpdate] = useState({});
  const [fieldsAppSetting, setFieldsAppSetting] = useState({});
  const [errorsAppSetting, setErrorsAppSetting] = useState({});
  const [size, setSize] = useState(10);
  const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [value, setValue] = useState('');
  const { appSettingList, total, loading } = useSelector(state => state.appSettings);

  const [initialState, setInitialState] = useState({
    _id: '',
    name: '',
    key: '',
    value: '',
  });

  const handleChange2 = (e, element) => {
    console.log(element)
    if (appSettingList && appSettingList.length > 0) {
      appSettingList.forEach((element) => {
        if (element.key === "YoutubeLink" && e.key === "Youtube") {
          setstate1(e.target.value);
        }
        if (element.key === "Email") {
          setstate2(e.target.value);
        }
        if (element.key === "WhatsApp") {
          setstate3(e.target.value);
        }
        if (element.key === "MobileNumber") {
          setstate4(e.target.value);
        }
        if (element.key === "TelegramLink") {
          setstate5(e.target.value);
        }
      });
    }

  };


  // console.log(initialState)

  useEffect(() => {
    let appsettingReq = {
      "keyWord": "",
      "pageNo": pageNo,
      "size": size
    }
    dispatch(appSettingActions.getAppSettingList(appsettingReq));

  }, []);


  const handleCreateOpenModal = () => {
    setCreateModal(true);
  }
  const savedatadata = (e) => {
    console.log(e)
  }



  const handleCreateHideModal = (data) => {
    setCreateModal(false);
    setFieldsAppSetting({});
    setErrorsAppSetting({});
  }

  // const handleUpdateOpenModal = (data) => {
  //   setFieldsUpdate(data);
  //   setUpdateModal(true);
  // }

  const handleUpdateHideModal = (data) => {
    setUpdateModal(false);
    setErrorsUpdate({});
  }

  const handleViewOpenModal = (data) => {
    setRowData(data);
    setViewModal(true);
  }

  const handleViewHideModal = (data) => {
    setViewModal(false);
  }

  const inputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsUpdate(prevState => ({ ...prevState, [name]: value }));
    setErrorsUpdate(prevState => ({ ...prevState, [name]: "" }));
  };

  const inputChangeCreate = (e) => {
    // e.preventDefault();
    // console.log(e)
    // const { name, value } = e.target;
    // setFieldsAppSetting(prevState => ({ ...prevState, [name]: value }));
    // setErrorsAppSetting(prevState => ({ ...prevState, [name]: "" }));
  };

  const updateAppSettingSubmit = (e) => {
    e.preventDefault();
    if (handleValidationUpdateAppSetting()) {

      let appsettingPageRefresh = {
        "keyWord": "",
        "pageNo": pageNo,
        "size": size
      }

      const { name, key, value, _id } = fieldsUpdate;
      dispatch(appSettingActions.updateAppSetting(
        {
          name: name,
          key: key,
          value: value,
          id: _id
        }
        , setUpdateModal, appsettingPageRefresh));
    }
  };

  const handleValidationUpdateAppSetting = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsUpdate.name || fieldsUpdate.name === "") {
      formIsValid = false;
      errors.name = DashboardJSON.ErrorMsg.name;
    }

    if (!fieldsUpdate.key || fieldsUpdate.key === "") {
      formIsValid = false;
      errors.key = DashboardJSON.ErrorMsg.key;
    }

    if (!fieldsUpdate.value || fieldsUpdate.value === "") {
      formIsValid = false;
      errors.value = DashboardJSON.ErrorMsg.value;
    }


    setErrorsUpdate(errors);
    return formIsValid;
  };


  const createAppSettingSubmit = (e) => {
    e.preventDefault();
    if (handleValidationCreateAppSetting()) {

      let appsettingPageRefresh = {
        "keyWord": "",
        "pageNo": pageNo,
        "size": size
      }

      const { name, key, value, _id } = fieldsAppSetting;
      dispatch(appSettingActions.createAppSetting(
        {
          name: name,
          key: key,
          value: value,
          id: _id
        }
        , setCreateModal, setFieldsAppSetting, appsettingPageRefresh));
    }
  };

  const handleValidationCreateAppSetting = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsAppSetting.name || fieldsAppSetting.name === "") {
      formIsValid = false;
      errors.name = DashboardJSON.ErrorMsg.name;
    }

    if (!fieldsAppSetting.key || fieldsAppSetting.key === "") {
      formIsValid = false;
      errors.key = DashboardJSON.ErrorMsg.key;
    }

    if (!fieldsAppSetting.value || fieldsAppSetting.value === "") {
      formIsValid = false;
      errors.value = DashboardJSON.ErrorMsg.value;
    }


    setErrorsAppSetting(errors);
    return formIsValid;
  };


  const disableAppSetting = (data) => {
    let appsettingIdReq = {
      "id": data._id,
    }
    let appsettingPageRefresh = {
      "keyWord": "",
      "pageNo": pageNo,
      "size": size
    }
    confirmAlert({

      title: 'Confirm to disable AppSetting?',
      message: `Are you sure to disable ${data.name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(appSettingActions.updateAppSettingStatus(appsettingIdReq, appsettingPageRefresh))
        },
        {
          label: 'No'
        }
      ]
    });
  }


  // const deleteAppSetting = (data) => {
  //   let appsettingIdReq = {
  //     "id": data._id,
  //   }

  //   let appsettingPageRefresh = {
  //     "keyWord": "",
  //     "pageNo": pageNo,
  //     "size": size
  //   }

  //   confirmAlert({

  //     title: 'Confirm to Delete?',
  //     message: `Are you sure you want  to delete ${data.name}?`,
  //     buttons: [
  //       {
  //         label: 'Yes',
  //         onClick: () => dispatch(appSettingActions.deleteAppSetting(appsettingIdReq, appsettingPageRefresh))
  //       },
  //       {
  //         label: 'No'
  //       }
  //     ]
  //   });
  // }


  const handleFile = (event) => {
    console.log("handleFileevent", event);

    // this.setState({ selectedFile: event.target.files[0] });
    setSelectedFile(event.target.files[0]);

    if (event.target.files[0]) {
      dispatch(appSettingActions.uploadImage(event.target.files[event.target.files.length - 1]));
      // this.setState({ selectedFile: null });
      setSelectedFile(null)

    }
    else {
      console.log("No File To Upload!")
    }

  }


  const [state1, setstate1] = useState("")
  const [state2, setstate2] = useState("")
  const [state3, setstate3] = useState("")
  const [state4, setstate4] = useState("")
  const [state5, setstate5] = useState("")


  useEffect(() => {
    if (appSettingList && appSettingList.length > 0) {
      appSettingList.forEach((element) => {
        if (element.key === "YoutubeLink") {
          setstate1(element.value);
        }
        if (element.key === "Email") {
          setstate4(element.value);
        }
        if (element.key === "WhatsApp") {
          setstate3(element.value);
        }
        if (element.key === "MobileNumber") {
          setstate2(element.value);
        }
        if (element.key === "TelegramLink") {
          setstate5(element.value);
        }
        if (element.key === "HowToPlay") {
          setValue(element.value);
        }
      });
    }
  }, [appSettingList]);

  const saveInfo = async (e) => {
    console.log(e)
    let dataofvalue;
    if (e.key === "TelegramLink") {
      dataofvalue = state5;
    }
    if (e.key === "YoutubeLink") {
      dataofvalue = state1;
    }
    if (e.key === "Email") {
      dataofvalue = state4;
    }
    if (e.key === "WhatsApp") {
      dataofvalue = state3;
    }
    if (e.key === "MobileNumber") {
      dataofvalue = state2;
    }
    if (e.key === "HowToPlay") {
      dataofvalue = value;
    }
    let appsettingPageRefresh = {
      "keyWord": "",
      "pageNo": pageNo,
      "size": size
    }
    let Obj = {
      name: e?.name,
      key: e?.key,
      value: dataofvalue,
      id: e?._id
    }
    dispatch(appSettingActions.updateAppSetting(Obj, appsettingPageRefresh))


  }



  return (
    <>
      <Loader loading={loading} />
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className="p-3 2xl:p-10 sm:p-5">
                    <div className="mx-auto max-w-screen-3xl">
                      <sectiion className="flex justify-between my-4 ">
                        <div>
                          <p className='text-2xl font-bold'>App Setting Management</p>
                        </div>
                        <div>
                          <span className="relative ">

                          </span>  &nbsp;
                        </div>

                      </sectiion>

                      <div>
                        <div className="relative  flex flex-col gap-5 ">
                          <div className='  flex md:flex-row flex-col justify-center  items-center gap-5  '>
                            {
                              appSettingList && appSettingList.length > 0 ?
                                appSettingList.map((element, index) => {


                                  if (element.key === "TelegramLink") {
                                    return (


                                      <form class="flex  w-full justify-start items-center  ">

                                        <div className=' relative w-full'>
                                          <label for="text" class="block mb-1 text-sm font-medium text-gray-900 ">{element && element.name} </label>
                                          <input value={state5} onChange={(e) => setstate5(e.target.value)} type="text" id="text" class="bg-gray-50  w-full  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                                          <FaRegSave title='Save' onClick={(e) => saveInfo(element, e)} className=' text-[#3e44d5] absolute  top-1/2 right-2 text-xl cursor-pointer' />
                                        </div>
                                        <div>

                                        </div>


                                      </form>

                                    )

                                  }

                                  if (element.key === "MobileNumber") {
                                    return (


                                      <form class="flex  justify-start w-full border-t-2 border-white p-2 items-center mt-2 ">

                                        <div className=' w-full relative'>
                                        <div className=' flex justify-between items-center text-xl mb-2 px-2'>
                                        <label for="text" class="block mb-1 text-sm font-medium text-gray-900 ">User Pannel Marquee </label>
                                            <div title='Save' onClick={(e) => saveInfo(element, e)} className=' text-white bg-[#3e44d5] px-2 py-1 rounded-lg cursor-pointer'>Save</div>
                                          </div>
                                          
                                          <textarea rows={3} value={state2} onChange={(e) => setstate2(e.target.value)} type="text" id="text" class="bg-gray-50  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-6 py-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                                          {/* <FaRegSave title='Save' onClick={(e) => saveInfo(element, e)} className=' text-[#3e44d5] absolute  top-1/2 right-2 text-xl cursor-pointer' /> */}
                                        </div>
                                        <div>

                                        </div>


                                      </form>

                                    )

                                  }
                                  if (element.key === "WhatsApp") {
                                    return (


                                      <form class="flex w-full justify-start items-center mt-2 ">

                                        <div className='w-full  border-t-2 border-white p-2 relative'>
                                          <div className=' flex justify-between items-center text-xl mb-2 px-2'>
                                            <label for="text" class="block mb-1  font-medium text-gray-900 ">Admin Pannel Marquee </label>
                                            <div title='Save' onClick={(e) => saveInfo(element, e)} className=' text-white bg-[#3e44d5] px-2 py-1 rounded-lg cursor-pointer'>Save</div>
                                          </div>
                                          <textarea rows={3} value={state3} onChange={(e) => setstate3(e.target.value)} type="text" id="text" class="bg-gray-50  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-6 py-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" required />

                                        </div>
                                        <div>

                                        </div>


                                      </form>

                                    )

                                  }
                                  if (element.key === "Email") {
                                    return (


                                      <form class="flex w-full  justify-start items-center mt-2 ">

                                        <div className='w-full relative'>
                                          <label for="text" class="block mb-1 text-sm font-medium text-gray-900 ">{element && element.name} </label>
                                          <input value={state4} onChange={(e) => setstate4(e.target.value)} type="text" id="text" class="bg-gray-50  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                                          <FaRegSave title='Save' onClick={(e) => saveInfo(element, e)} className=' text-[#3e44d5] absolute  top-1/2 right-2 text-xl cursor-pointer' />
                                        </div>
                                        <div>

                                        </div>


                                      </form>

                                    )

                                  }
                                  if (element.key === "YoutubeLink") {
                                    return (


                                      <form class="flex  justify-start w-full items-center mt-2 ">

                                        <div className='w-full relative'>
                                          <label for="text" class="block mb-1 text-sm font-medium text-gray-900 ">{element && element.name} </label>
                                          <input value={state1} onChange={(e) => setstate1(e.target.value)} type="text" id="text" class="bg-gray-50  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                                          <FaRegSave title='Save' onClick={(e) => saveInfo(element, e)} className=' text-[#3e44d5] absolute  top-1/2 right-2 text-xl cursor-pointer' />
                                        </div>
                                        <div>

                                        </div>


                                      </form>

                                    )

                                  }

                                }) : null
                            }
                          </div>

                          <div className=' flex-1 h-full '>
                            {appSettingList && appSettingList.length > 0 ? (
                              appSettingList.map((element, index) => {
                                if (element && element.key && element.key === "HowToPlay") {
                                  return (<div className=''>
                                    <div className=' mb-1'> How To Play</div>
                                    <ReactQuill className='h-96  w-full   pb-14' theme="snow" value={value} onChange={setValue} />
                                    <button className='bg-[#3e44d5] text-xl  text-white w-full rounded-md p-1' onClick={(e) => saveInfo(element, e)}> Save</button>
                                  </div>
                                  )
                                }
                              })
                            ) : null}



                          </div>

                        </div>
                      </div>



                    </div>
                  </div>

                  {/* {
                    isMobile ?
                      <nav className="relative z-0 flex justify-end mt-5 w-76">
                        {
                          total && total > 10 ?
                            <ReactPaginate
                              previousLabel={'Prev'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={total / size}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={1}
                              onPageChange={handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                            />
                            : null}
                      </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                        {
                          total && total > 10 ?
                            <ReactPaginate
                              previousLabel={'Previous'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={total / size}
                              marginPagesDisplayed={3}
                              pageRangeDisplayed={3}
                              onPageChange={handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                            />
                            : null}
                      </nav>
                  } */}


                </main>
              </div>
            </div>
          </div>
        </div>
      </div>





      <CreateAppSettingModal
        createModal={createModal}
        handleCreateHideModal={handleCreateHideModal}
        handleFile={handleFile}
        inputChangeCreate={inputChangeCreate}
        fieldsAppSetting={fieldsAppSetting}
        errorsAppSetting={errorsAppSetting}
        createAppSettingSubmit={createAppSettingSubmit}
      />

      <UpdateAppSettingModal
        updateModal={updateModal}
        handleUpdateHideModal={handleUpdateHideModal}
        handleFile={handleFile}
        inputChange={inputChange}
        fieldsUpdate={fieldsUpdate}
        errorsUpdate={errorsUpdate}
        updateAppSettingSubmit={updateAppSettingSubmit}
      />

      <ViewAppSettingModal
        viewModal={viewModal}
        handleViewHideModal={handleViewHideModal}
        rowData={rowData}
      />


    </>
  );
};

export default AppSettingManagement;
