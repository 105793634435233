import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../_actions';
import LoginJSON from './login.json'
import { useNavigate } from 'react-router';
import { LuEyeOff } from "react-icons/lu";
import { LuEye } from "react-icons/lu";
import logo from "./logo.png"
import Loader from "../../components/Loader/Loader";
import background from "./background.png"

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fieldslogin, setFieldsLogin] = useState({});
  const [errorslogin, setErrorsLogin] = useState({});
  let selector = useSelector(state=>state);
// console.log("---------------->",selector)
  const inputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsLogin(prevState => ({ ...prevState, [name]: value }));
    setErrorsLogin(prevState => ({ ...prevState, [name]: "" }));
  };

  const loginSubmit = (e) => {
    e.preventDefault();
    if (handleValidationLogin()) {
      const { userName, password } = fieldslogin;
      dispatch(userActions.login({ userName: userName, password: password }, navigate));
    }
  };

  const handleValidationLogin = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldslogin.userName || fieldslogin.userName === "") {
      formIsValid = false;
      errors.userName = LoginJSON.ErrorMsg.email;
    }

    if (!fieldslogin.password) {
      formIsValid = false;
      errors.password = LoginJSON.ErrorMsg.password;
    }


    setErrorsLogin(errors);
    return formIsValid;
  };
  let [showPassword, setShowPassword] = useState(false)
  let {users} =selector?selector:{};
  let {loading} = users?users:{};

  return (
    <>
      <div   className="bg-black/80">
        <Loader loading={loading}/>
        <div className="relative">
          <div  className="min-h-screen  flex flex-col justify-center   relative z-20">
 
            <div  className=" mx-auto w-full  bg-no-repeat rounded-lg  max-w-lg">
              <div style={{backgroundImage:`url(${background})` , backgroundSize:"cover"}} className="  mx-auto h-screen flex justify-center items-center flex-col bg-no-repeat py-0  rounded-xl ">
                <div className=" flex justify-center items-center pt-44 ">
                  <img src={logo} className=" w-36 h-36" />
                </div>
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                  <h2 style={{ fontFamily: 'Algerian' }} className="mb-0 text-center text-3xl leading-9 font-bold capitalize text-dark-400  text-[#F15C23]">{LoginJSON.messageWeLCome}</h2>
                </div>

                <form autoComplete="off">
                  <div className="{otpSent?'disableArea':''}">
                    <div className="mt-0  shadow-sm relative">
                      <input className={`px-12 py-5 placeholder-blueGray-400 text-blueGray-600 rounded-lg bg-white  text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${!errorslogin["userName"] ? "border border-gray-400 placeholder-gray-500" : "border border-opacity-100 border-red-500  "}`}
                        id="userName" name="userName" placeholder="Admin" type="text" onChange={inputChange} />
                      {errorslogin["userName"] ?
                        <div className="invalid-feedback  text-red-500">
                          {errorslogin["userName"]}
                        </div>
                        : null}
                      <span className="absolute top-4 left-5 text-gray-500"><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                      </svg></span>
                    </div>
                  </div>

                  <div className="mt-0">
                    <div className="mt-1 rounded-md shadow-sm relative">
                      <input className={`border-1 px-12 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white text-black rounded-lg text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${!errorslogin["password"] ? " placeholder-gray-500" : "border-opacity-100 border border-red-500 "}`}
                        id="password" name="password" placeholder="Password" type={showPassword ? "text" : "password"} onChange={inputChange} />
                     
                      <span className="absolute top-4 left-5 text-gray-500 "><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                      </svg></span>

                      {showPassword ? <LuEye onClick={() => setShowPassword(prev => !prev)} className=" text-gray-500 cursor-pointer absolute right-3 bottom-6" />
                        : <LuEyeOff onClick={() => setShowPassword(prev => !prev)} className=" text-gray-500 absolute cursor-pointer right-3 bottom-6" />}
                    </div>
                    {errorslogin["password"] ?
                        <div className="invalid-feedback  text-red-500">
                          {errorslogin["password"]}
                        </div>
                        : null}
                  </div>

                  <div className="mt-0 w-64 sm:w-72 mx-auto py-6">
                    <button style={{ fontFamily: 'Algerian' }} className="bg-[#f15c23] w-full mx-auto flex justify-center py-3 capitalize px-4 border text-lg xl font-semibold rounded-full text-white border-shine-400 hover:bg-[#f15c23] hover:text-white focus:outline-none  focus:shadow-outline-yellow active:bg-[#f15c23] transition duration-150 ease-in-out" type="submit" onClick={loginSubmit}>{LoginJSON.signin}</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
