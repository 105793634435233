import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashboardJSON from './WalletManagement.json'
import { gamesActions, marketActions, walletActions, userActions } from '../../_actions';
import { IoWalletOutline } from "react-icons/io5";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ReactPaginate from 'react-paginate';
import { isMobile } from "react-device-detect";
import Loader from '../../components/Loader/Loader';


// import Table from '../../components/Atoms/Table/Table';


const AddFund = () => {
  const dispatch = useDispatch();
  let [selectedFile, setSelectedFile] = useState(null);
  let [rowData, setRowData] = useState(null);
  let [createModal, setCreateModal] = useState(false);
  let [updateModal, setUpdateModal] = useState(false);
  let [viewModal, setViewModal] = useState(false);
  const [marketSelected, setMarketSelected] = useState([]);
  const [fieldsUpdate, setFieldsUpdate] = useState({});
  const [errorsUpdate, setErrorsUpdate] = useState({});
  const [fieldsGame, setFieldsGame] = useState({});
  const [errorsGame, setErrorsGame] = useState({});
  const [selectedMarket, setSelectedMarket] = useState({});
  const [size, setSize] = useState(10);
  const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);

  const { allMarket } = useSelector(state => state.markets);
  const { userList, userTotal } = useSelector(state => state.users);
  const selector =useSelector(state => state)
const {games,wallet} = selector?selector:{};
let {allUserList } = games?games:{};
let { loading} = wallet?wallet:{};

  useEffect(() => {
    let userReq = {
      "keyWord": "",
      "pageNo": pageNo,
      "sortBy": "slug",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": size
    }
    dispatch(gamesActions.getAllUser());
    dispatch(userActions.getAdminProfile());
  }, []);

  const inputChangeCreate = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsGame(prevState => ({ ...prevState, [name]: value }));
    setErrorsGame(prevState => ({ ...prevState, [name]: "" }));
  };

  const createGameSubmit = (e) => {
    e.preventDefault();
    if (handleValidationCreateGame()) {
      const { userId, amount } = fieldsGame;
      dispatch(walletActions.depositeBalanceByAdmin({
        userId: userId,
        amount: amount,
      }));
    }
  };

  const handleValidationCreateGame = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsGame.userId || fieldsGame.userId === "") {
      formIsValid = false;
      errors.userId = "Select User";
    }

    if (!fieldsGame.amount || fieldsGame.amount === "") {
      formIsValid = false;
      errors.amount = "Enter amount";
    }

    setErrorsGame(errors);
    return formIsValid;
  };
  let [localStoragedata, setlocalStoragedata] = useState()
  useEffect(() => {
    const stateData = window.sessionStorage.getItem('adminuser')
    setlocalStoragedata(JSON.parse(stateData));

  }, [])

  let {users} = selector?selector:{};
  let {adminProfileDetails} = users?users:{};
  let {adminData} = adminProfileDetails?adminProfileDetails:{};
  let {balance} = adminProfileDetails?adminProfileDetails:{};

  console.log(localStoragedata)
  console.log(balance)


  return (
    <>
    <Loader loading={loading}/>
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className="p-3 2xl:p-10 sm:p-5">
                    <div className="mx-auto max-w-screen-3xl">
                      <sectiion className="flex justify-between my-4 ">
                        <div className='text-xl font-semibold'>Dashboard{" "}/{" "}<span className='text-[#7D1970]'>Add Fund</span></div>
                      </sectiion>

                      <div className='bg-white rounded-xl'>
                        <div className='flex justify-between items-center px-3 border-b'>
                          <span className="p-5 text-lg text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800">
                            Add Balance In User Wallet
                          </span>
                          {/* localStoragedata && localStoragedata.roleId == 0  */}

{localStoragedata&&localStoragedata?.roleId !=0 ? <span>
  <span className=' flex  justify-center items-center bg-[#3E44D5] text-white p-3 rounded-lg'>

<IoWalletOutline className=" text-2xl"/>{balance&&balance||"0"}
  </span>
</span>:""}

                        </div>

                        <div className='p-5 space-y-2'>
                          <div className="flex space-x-5 ">
                            <div className="mt-1 shadow-sm w-full">
                              <label class="block text-gray-700 text-base mb-2" for="name">User List :</label>
                              <select className="w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-lg focus:outline-none"
                                id="userId" name="userId" placeholder="name"
                                value={fieldsGame && fieldsGame["userId"] ? fieldsGame["userId"] : ""} type="text" onChange={inputChangeCreate} >
                                         <option value="">Select User</option>
                              
                                {allUserList && allUserList.length > 0 ? allUserList.map((element, index) => (
                                  <option value={element && element._id ? element._id : ""}>{element && element.userName ? element.userName : ""}</option>
                                )) : null}
                              </select>
                              {errorsGame && errorsGame["userId"] ?
                                <div className="text-yellow-600 invalid-feedback">
                                  {errorsGame["userId"]}
                                </div>
                                : null}
                            </div>
                            <div className="w-full mt-1 shadow-sm">
                              <label class="block text-gray-700 text-base mb-2" for="gamename">Amount :</label>
                              <input type="number" className="w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-lg focus:outline-none"
                                id="amount" name="amount" placeholder="Enter Amount" value={fieldsGame && fieldsGame["amount"] ? fieldsGame["amount"] : ""}  onChange={inputChangeCreate} />
                              {errorsGame && errorsGame["amount"] ?
                                <div className="text-yellow-600 invalid-feedback">
                                  {errorsGame["amount"]}
                                </div>
                                : null}
                            </div>
                          </div>
                          <div className="w-full flex justify-start">
                            <button className="flex justify-center px-4 py-2 text-base font-semibold text-white capitalize  transition duration-150 ease-in-out bg-[#7D1970] border border-[#7D1970] rounded-xl focus:outline-none" type="button" onClick={createGameSubmit}>Submit</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div >
      </div >
    </>
  );
};

export default AddFund;
