import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoMdCloseCircle } from "react-icons/io";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import { MdOutlineCreditCard } from "react-icons/md";
import { FaHome } from "react-icons/fa";
import { FaUserGear } from "react-icons/fa6";
import { FaShoppingBasket } from "react-icons/fa";
import { FaRegNewspaper } from "react-icons/fa";
import { ImUsers } from "react-icons/im";
import { LuGamepad2 } from "react-icons/lu";
import { PiSlideshowBold } from "react-icons/pi";
import { BiWalletAlt } from "react-icons/bi";
import { TbReportAnalytics } from "react-icons/tb";
// import { IoMdMenu } from "react-icons/io";
import { GiAngelWings } from "react-icons/gi";
import { TbTargetArrow } from "react-icons/tb";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdKeyboardArrowRight } from "react-icons/md";
import { BsBank2 } from "react-icons/bs";
import profitTowin from "./profitTowin.png";
function Sidebar({ SidebarJSON, navbarOpen, setNavbarOpen }) {
  const location = useLocation();
  const navigate = useNavigate();

  const [showSection, setShowSection] = React.useState("");

  // const onClickMenu = (url) => {
  //     history.push(url);
  //     setNavbarOpen(!navbarOpen)
  //   }

  const onClickMenu = (url) => {
    navigate(url);
    setTimeout(() => {
      // setNavbarOpen(!navbarOpen);
    }, 150);
  };

  const handleNavbar = () => {
    setNavbarOpen(!navbarOpen);
  };

  let [localStoragedata, setlocalStoragedata] = useState();
  useEffect(() => {
    const stateData = window.sessionStorage.getItem("adminuser");
    setlocalStoragedata(JSON.parse(stateData));
  }, []);

  return (
    <>
      <button
        className={` ${
          navbarOpen === true ? "hidden" : "flex"
        } absolute top-0 left-0 px-5 items-center justify-center border-r border-gray-200 text-white focus:outline-none focus:bg-gray-800 focus:text-gray-600 z-50 h-16 `}
        onClick={() => setNavbarOpen(!navbarOpen)}
      >
        <svg
          className="h-6 w-6"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 6h16M4 12h16M4 18h7"
          ></path>
        </svg>
      </button>
      {/* lg:flex lg:static  */}
      <div
        className={`${
          navbarOpen === false ? "hidden" : "flex"
        } fixed left-0 inset-y-0   w-72 z-50 flex-col`}
      >
        <div className="flex flex-col w-72 relative flex-1 h-full max-h-full">
          {/* onClick={() => onClickMenu('/app/dashboard')} */}
          <div className="cursor-pointer relative flex items-center  border-b-2   flex-shrink-0 px-4  bg-white h-32  border-gray-200 w-full z-50">
            <img
              src={profitTowin}
              alt=""
              className=" relative flex justify-center h-28 w-40 items-center  md:ml-6"
            />
            {/* <h1 className=" text-white">
                            {SidebarJSON.SidebarHeaderName}
                        </h1> */}
            <div className="  items-end absolute right-3 top-3">
              <GiHamburgerMenu
                onClick={handleNavbar}
                className=" text-gray-500 text-xl  block"
              />
            </div>
          </div>
          <nav className="overflow-y-auto bg-white flex flex-col flex-wrap items-center justify-between relative  w-72 z-10 px-3  h-full overflow-auto">
            <div className="px-0 flex flex-col flex-wrap  pt-2 items-center w-full mx-auto flex-1">
              <div className={"relative z-40 flex-1 w-full space-y-1"}>
                <div className="">
                  <span
                    onClick={() =>
                      onClickMenu("/app/dashboard", handleNavbar())
                    }
                    className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150   ${
                      location.pathname === "/app/dashboard"
                        ? "text-white bg-[#3e44d5] rounded-md"
                        : "text-black   hover:text-[#3e44d5]"
                    }`}
                  >
                    <span className="group-hover:text-[#3e44d5]">
                      <FaHome size={20} />
                    </span>
                    <span className="text-sm  lg:font-lg">Dashboard</span>
                  </span>
                  {/* {localStoragedata&&localStoragedata.roleId !==0 &&  <span onClick={() => onClickMenu('/app/user', handleNavbar())} className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150   ${location.pathname === "/app/user" ||
                                        location.pathname.includes("/app/user/") ||
                                        location.pathname.includes("/app/EditUser/")
                                        ? "text-white bg-[#3e44d5] rounded-md"
                                        : "text-black"
                                        }`}>
                                        <span className="group-hover:text-[#3e44d5]"><FaUserGear size={20} /></span>
                                        <span className="text-sm  lg:font-lg">User Management</span>
                                    </span>} */}
                  {/* {localStoragedata && localStoragedata.roleId == 0 &&
                                        <span onClick={() => onClickMenu('/app/declareResult', handleNavbar())} className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/declareResult" ? "text-white bg-[#3e44d5] rounded-md" : "text-black   hover:text-[#3e44d5]"}`}>
                                            <span className="group-hover:text-[#3e44d5]"><FaRegNewspaper size={20} /></span>
                                            <span className="text-sm  lg:font-lg">Declare Result</span>
                                        </span>} */}

                  {/* {localStoragedata && localStoragedata.roleId == 0 &&
                                        <span onClick={() => onClickMenu('/app/declareResultFatafat', handleNavbar())} className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/declareResultFatafat" ? "text-white bg-[#3e44d5] rounded-md" : "text-black   hover:text-[#3e44d5]"}`}>
                                            <span className="group-hover:text-[#3e44d5]"><FaRegNewspaper size={20} /></span>
                                            <span className="text-sm  lg:font-lg">Declare FataFat Result</span>
                                        </span>} */}

                  {localStoragedata && localStoragedata.roleId == 0 && (
                    <span
                      onClick={() =>
                        onClickMenu("/app/winnerList", handleNavbar())
                      }
                      className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${
                        location.pathname === "/app/winnerList"
                          ? "text-white bg-[#3e44d5] rounded-md"
                          : "text-black   hover:text-[#3e44d5]"
                      }`}
                    >
                      <span className="group-hover:text-[#3e44d5]">
                        <GiAngelWings size={20} />
                      </span>
                      <span className="text-sm  lg:font-lg">Winner List</span>
                    </span>
                  )}
                  <h2 className=" w-full h-[2px] bg-[#3e44d5] my-1"></h2>

                  {/* <span onClick={() => onClickMenu('/app/appsetting',handleNavbar())} className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/appsetting" ? "text-white bg-[#3e44d5] rounded-md" : "text-black   hover:text-[#3e44d5]" }`}>
                                        <span className="group-hover:text-[#3e44d5]"><MdOutlineCreditCard size={20} /></span>
                                        <span className="text-sm  lg:font-lg">App Setting Management</span>
                                    </span> */}

                  {/* {localStoragedata && localStoragedata.roleId == 0 && <span onClick={() => onClickMenu('/app/bankdetails', handleNavbar())} className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/bankdetails" ? "text-white bg-[#3e44d5] rounded-md" : "text-black   hover:text-[#3e44d5]"}`}>
                                        <span className="group-hover:text-[#744939]"><BsBank2 size={20} /></span>
                                        <span className="text-sm  lg:font-lg">Bank Management</span>
                                    </span>} */}
                  {/* SUPER_ADMIN: 0,
                ADMIN: 1,
                SUPER_MASTER: 2,
                MASTER: 3,
                AGENT: 4,
                OPERATOR: 20,
                CLIENT: 100 */}
                  <span
                    onClick={() =>
                      onClickMenu("/app/downsideUser", handleNavbar())
                    }
                    className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${
                      location.pathname === "/app/downsideUser"
                        ? "text-white bg-[#3e44d5] rounded-md"
                        : "text-black   hover:text-[#3e44d5]"
                    }`}
                  >
                    <span className="group-hover:text-[#3e44d5]">
                      <ImUsers size={20} />
                    </span>
                    {localStoragedata && localStoragedata.roleId == 0 && (
                      <span className="text-sm  lg:font-lg">ADMIN</span>
                    )}
                    {localStoragedata && localStoragedata.roleId == 1 && (
                      <span className="text-sm  lg:font-lg">SUPER MASTER</span>
                    )}
                    {localStoragedata && localStoragedata.roleId == 2 && (
                      <span className="text-sm  lg:font-lg">MASTER</span>
                    )}
                    {localStoragedata && localStoragedata.roleId == 3 && (
                      <span className="text-sm  lg:font-lg">AGENT</span>
                    )}
                    {localStoragedata && localStoragedata.roleId == 4 && (
                      <span className="text-sm  lg:font-lg">OPERATOR</span>
                    )}
                    {localStoragedata && localStoragedata.roleId == 20 && (
                      <span className="text-sm  lg:font-lg">CLIENT</span>
                    )}
                  </span>

                  {/* <span onClick={() => onClickMenu('/app/notification', handleNavbar())} className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/notification" ? "text-white bg-[#3e44d5] rounded-md" : "text-black   hover:text-[#3e44d5]"}`}>
                                        <span className="group-hover:text-[#3e44d5]"><MdNotificationsNone size={20} /></span>
                                        <span className="text-sm  lg:font-lg">Notification Managemnt</span>
                                    </span> */}

                  {localStoragedata && localStoragedata.roleId == 0 && (
                    <div className="cursor-pointer group">
                      {showSection === "settingManagement" ? (
                        <span
                          onClick={() => setShowSection("")}
                          className="flex items-center px-1 py-2 space-x-4 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9"
                        >
                          <span className="group-hover:text-[#3e44d5]">
                            <BiWalletAlt size={20} />
                          </span>
                          <span className="text-sm group-hover:text-[#3e44d5]  lg:font-lg">
                            Setting Management
                          </span>
                          {showSection === "settingManagement" ? (
                            <HiChevronUp className="group-hover:text-[#3e44d5]" />
                          ) : (
                            <HiChevronDown className="group-hover:text-[#3e44d5]" />
                          )}
                        </span>
                      ) : (
                        <span
                          onClick={() => setShowSection("settingManagement")}
                          className="flex items-center px-1 py-2 space-x-4 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9"
                        >
                          <span className="group-hover:text-[#3e44d5]">
                            <BiWalletAlt size={20} />{" "}
                          </span>
                          <span className="text-sm group-hover:text-[#3e44d5]  lg:font-lg">
                            Setting Management
                          </span>
                          {showSection === "settingManagement" ? (
                            <HiChevronUp className="group-hover:text-[#3e44d5]" />
                          ) : (
                            <HiChevronDown className="group-hover:text-[#3e44d5]" />
                          )}
                        </span>
                      )}
                      {showSection === "settingManagement" ? (
                        <div className="  flex flex-col pl-10">
                          <span
                            onClick={() =>
                              onClickMenu("/app/appsetting", handleNavbar())
                            }
                            className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${
                              location.pathname === "/app/appsetting"
                                ? "text-white bg-[#3e44d5] rounded-md"
                                : "text-black   hover:text-[#3e44d5]"
                            }`}
                          >
                            <MdKeyboardArrowRight />{" "}
                            <span className="text-sm  lg:font-lg">
                              App Setting
                            </span>
                          </span>
                          <span
                            onClick={() =>
                              onClickMenu("/app/slider", handleNavbar())
                            }
                            className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${
                              location.pathname === "/app/slider"
                                ? "text-white bg-[#3e44d5] rounded-md"
                                : "text-black   hover:text-[#3e44d5]"
                            }`}
                          >
                            <MdKeyboardArrowRight />{" "}
                            <span className="text-sm  lg:font-lg">
                              Slider Setting
                            </span>
                          </span>
                        </div>
                      ) : null}
                    </div>
                  )}

                  <h2 className=" w-full h-[2px] bg-[#3e44d5] my-1"></h2>
                </div>

                {localStoragedata && localStoragedata.roleId == 0 && (
                  <div className="cursor-pointer group">
                    {showSection === "game" ? (
                      <span
                        onClick={() => setShowSection("")}
                        className="flex items-center px-1 py-2 space-x-4 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9"
                      >
                        <span className="group-hover:text-[#3e44d5]">
                          <LuGamepad2 size={20} />
                        </span>
                        <span className="text-sm group-hover:text-[#3e44d5]  lg:font-lg">
                          Game Management
                        </span>
                        {showSection === "game" ? (
                          <HiChevronUp className="group-hover:text-[#3e44d5]" />
                        ) : (
                          <HiChevronDown className="group-hover:text-[#3e44d5]" />
                        )}
                      </span>
                    ) : (
                      <span
                        onClick={() => setShowSection("game")}
                        className="flex items-center px-1 py-2 space-x-4 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9"
                      >
                        <span className="group-hover:text-[#3e44d5]">
                          <LuGamepad2 size={20} />{" "}
                        </span>
                        <span className="text-sm group-hover:text-[#3e44d5]  lg:font-lg">
                          Game Management
                        </span>
                        {showSection === "game" ? (
                          <HiChevronUp className="group-hover:text-[#3e44d5]" />
                        ) : (
                          <HiChevronDown className="group-hover:text-[#3e44d5]" />
                        )}
                      </span>
                    )}
                    {showSection === "game" ? (
                      <div className=" mb-5  flex flex-col pl-10">
                        <span
                          onClick={() =>
                            onClickMenu("/app/brand", handleNavbar())
                          }
                          className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${
                            location.pathname === "/app/brand"
                              ? "text-white bg-[#3e44d5] rounded-md"
                              : "text-black   hover:text-[#3e44d5]"
                          }`}
                        >
                          {/* <span className="group-hover:text-[#3e44d5]"><LuGamepad2 size={20} /></span> */}
                          <MdKeyboardArrowRight />{" "}
                          <span className="text-sm  lg:font-lg">Brand </span>
                        </span>

                        {/* <span onClick={() => onClickMenu('/app/Kolkatagame', handleNavbar())} className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/Kolkatagame" ? "text-white bg-[#3e44d5] rounded-md" : "text-black   hover:text-[#3e44d5]"}`}>
                                               
                                                <MdKeyboardArrowRight /> <span className="text-sm  lg:font-lg">Kolkata Game </span>
                                            </span> */}

                        <span
                          onClick={() =>
                            onClickMenu("/app/game", handleNavbar())
                          }
                          className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${
                            location.pathname === "/app/game"
                              ? "text-white bg-[#3e44d5] rounded-md"
                              : "text-black   hover:text-[#3e44d5]"
                          }`}
                        >
                          {/* <span className="group-hover:text-[#3e44d5]"><LuGamepad2 size={20} /></span> */}
                          <MdKeyboardArrowRight />{" "}
                          <span className="text-sm  lg:font-lg">Game </span>
                        </span>
                        <span
                          onClick={() =>
                            onClickMenu("/app/Brandgame", handleNavbar())
                          }
                          className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${
                            location.pathname === "/app/Brandgame"
                              ? "text-white bg-[#3e44d5] rounded-md"
                              : "text-black   hover:text-[#3e44d5]"
                          }`}
                        >
                          {/* <span className="group-hover:text-[#3e44d5]"><LuGamepad2 size={20} /></span> */}
                          <MdKeyboardArrowRight />{" "}
                          <span className="text-sm  lg:font-lg">
                            Brand Game{" "}
                          </span>
                        </span>

                        <span
                          onClick={() =>
                            onClickMenu("/app/market", handleNavbar())
                          }
                          className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${
                            location.pathname === "/app/market" ||
                            location.pathname.includes("app/EditMarket/")
                              ? "text-white bg-[#3e44d5] rounded-md"
                              : "text-black   hover:text-[#3e44d5]"
                          }`}
                        >
                          {/* <span className="group-hover:text-[#3e44d5]"><FaShoppingBasket size={20} /></span> */}
                          <MdKeyboardArrowRight />{" "}
                          <span className="text-sm  lg:font-lg">
                            Market Management
                          </span>
                        </span>
                      </div>
                    ) : null}
                  </div>
                )}

                {localStoragedata && localStoragedata.roleId == 0 && (
                  <div className="cursor-pointer group">
                    {showSection === "resultDeclare" ? (
                      <span
                        onClick={() => setShowSection("")}
                        className="flex items-center px-1 py-2 space-x-4 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9"
                      >
                        <span className="group-hover:text-[#3e44d5]">
                          <TbTargetArrow size={20} />
                        </span>
                        <span className="text-sm group-hover:text-[#3e44d5]  lg:font-lg">
                          Result Management
                        </span>
                        {showSection === "resultDeclare" ? (
                          <HiChevronUp className="group-hover:text-[#3e44d5]" />
                        ) : (
                          <HiChevronDown className="group-hover:text-[#3e44d5]" />
                        )}
                      </span>
                    ) : (
                      <span
                        onClick={() => setShowSection("resultDeclare")}
                        className="flex items-center px-1 py-2 space-x-4 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9"
                      >
                        <span className="group-hover:text-[#3e44d5]">
                          <TbTargetArrow size={20} />{" "}
                        </span>
                        <span className="text-sm group-hover:text-[#3e44d5]  lg:font-lg">
                          Result Management
                        </span>
                        {showSection === "resultDeclare" ? (
                          <HiChevronUp className="group-hover:text-[#3e44d5]" />
                        ) : (
                          <HiChevronDown className="group-hover:text-[#3e44d5]" />
                        )}
                      </span>
                    )}
                    {showSection === "resultDeclare" ? (
                      <div className=" mb-5  flex flex-col pl-10">
                        <span
                          onClick={() =>
                            onClickMenu("/app/declareResult", handleNavbar())
                          }
                          className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${
                            location.pathname === "/app/declareResult"
                              ? "bg-[#3e44d5] text-white  rounded-lg "
                              : "text-black   hover:text-[#3e44d5]"
                          }`}
                        >
                          <MdKeyboardArrowRight />{" "}
                          <span className="text-sm  lg:font-lg">
                            Game Result Game
                          </span>
                        </span>
                        <span
                          onClick={() =>
                            onClickMenu(
                              "/app/declareResultFatafat",
                              handleNavbar()
                            )
                          }
                          className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${
                            location.pathname === "/app/declareResultFatafat"
                              ? "bg-[#3e44d5] text-white  rounded-lg "
                              : "text-black   hover:text-[#3e44d5]"
                          }`}
                        >
                          <MdKeyboardArrowRight />{" "}
                          <span className="text-sm  lg:font-lg">
                            FataFat Result Declare
                          </span>
                        </span>
                      </div>
                    ) : null}
                  </div>
                )}

                <div className="cursor-pointer group">
                  {showSection === "report" ? (
                    <span
                      onClick={() => setShowSection("")}
                      className="flex items-center px-1 py-2 space-x-4 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9"
                    >
                      <span className="group-hover:text-[#3e44d5]">
                        <TbReportAnalytics size={20} />
                      </span>
                      <span className="text-sm group-hover:text-[#3e44d5]  lg:font-lg">
                        Report Management
                      </span>
                      {showSection === "report" ? (
                        <HiChevronUp className="group-hover:text-[#3e44d5]" />
                      ) : (
                        <HiChevronDown className="group-hover:text-[#3e44d5]" />
                      )}
                    </span>
                  ) : (
                    <span
                      onClick={() => setShowSection("report")}
                      className="flex items-center px-1 py-2 space-x-4 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9"
                    >
                      <span className="group-hover:text-[#3e44d5]">
                        <TbReportAnalytics size={20} />{" "}
                      </span>
                      <span className="text-sm group-hover:text-[#3e44d5]  lg:font-lg">
                        Report Management
                      </span>
                      {showSection === "report" ? (
                        <HiChevronUp className="group-hover:text-[#3e44d5]" />
                      ) : (
                        <HiChevronDown className="group-hover:text-[#3e44d5]" />
                      )}
                    </span>
                  )}
                  {showSection === "report" ? (
                    <div className=" mb-5  flex flex-col pl-10">
                      <span
                        onClick={() =>
                          onClickMenu("/app/betHistory", handleNavbar())
                        }
                        className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${
                          location.pathname === "/app/betHistory"
                            ? "bg-[#3e44d5] text-white  rounded-lg "
                            : "text-black   hover:text-[#3e44d5]"
                        }`}
                      >
                        <MdKeyboardArrowRight />{" "}
                        <span className="text-sm  lg:font-lg">
                          Bid Point History
                        </span>
                      </span>
                      <span
                        onClick={() =>
                          onClickMenu("/app/userbidhistory", handleNavbar())
                        }
                        className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${
                          location.pathname === "/app/userbidhistory"
                            ? "bg-[#3e44d5] text-white  rounded-lg "
                            : "text-black   hover:text-[#3e44d5]"
                        }`}
                      >
                        <MdKeyboardArrowRight />{" "}
                        <span className="text-sm  lg:font-lg">
                          User Bid History
                        </span>
                      </span>

                      <span
                        onClick={() =>
                          onClickMenu("/app/ledger", handleNavbar())
                        }
                        className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${
                          location.pathname === "/app/ledger"
                            ? "bg-[#3e44d5] text-white  rounded-lg "
                            : "text-black   hover:text-[#3e44d5]"
                        }`}
                      >
                        <MdKeyboardArrowRight />{" "}
                        <span className="text-sm  lg:font-lg">
                        My Point Statement
                        </span>
                      </span>

                      <span
                        onClick={() =>
                          onClickMenu("/app/betsledger", handleNavbar())
                        }
                        className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${
                          location.pathname === "/app/betsledger"
                            ? "bg-[#3e44d5] text-white  rounded-lg "
                            : "text-black   hover:text-[#3e44d5]"
                        }`}
                      >
                        <MdKeyboardArrowRight />{" "}
                        <span className="text-sm  lg:font-lg">My Ledger</span>
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
